import './paginator.css'

const Paginator = ({page, totalPage, onPageItemClicked, pages}) => {

  const handlePageItemClicked = (pageClicked) => {
    if(onPageItemClicked){
      onPageItemClicked(pageClicked)
    }
  }

  const handleNextItemClicked = () => {
    if(page >= totalPage - 1){
      alert('Bạn đang ở trang cuối cùng');
      return;
    }
    if(onPageItemClicked){
      onPageItemClicked(page + 1 + 1);
    }
  }

  const handlePrevItemClicked = () => {
    if(page <= 0){
      alert('Bạn đang ở trang đầu tiên');
      return;
    }
    if(onPageItemClicked){
      onPageItemClicked(page + 1 - 1);
    }
  }


  return (
      <div className={"paginator"}>
        <div className={"paginator-info"}>
          Trang:
          <span style={{fontWeight: "bold"}}> {page + 1} </span> trên tổng số
          <span style={{fontWeight: "bold"}}> {totalPage} </span>
          trang
        </div>
        <div className={"paginator-bar"}>
          <div className={"paginator-item"} onClick={handlePrevItemClicked}>
            Trang trước
          </div>
          {
            pages.map(p => {
              return (
                  <div  key={p} className={'paginator-item ' + (page +1 === p ? ' page-item-active' : '')} onClick={() => handlePageItemClicked(p)}>
                    {p}
                  </div>
              )
            })
          }
          <div className={"paginator-item"} onClick={handleNextItemClicked}>
            Trang tiếp theo
          </div>
        </div>
      </div>
  )
}

export default Paginator
