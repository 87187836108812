import './hotelsTable.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare, faTrash} from "@fortawesome/free-solid-svg-icons";

const HotelsTable = ({loading, hotels, onDeleteBtnClicked, onEditBtnClicked}) => {
  return (
      <>
        <h3 className={"page-title"}>DANH SÁCH KHÁCH SẠN</h3>
        {
            loading && (
                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <div className="spinner-border" role="status">
                  </div>
                </div>
            )
        }
        <table>
          <thead>
          <tr>
            <td>Tên khách sạn</td>
            <td>Ngày tạo</td>
            <td></td>
            <td></td>
          </tr>
          </thead>
          <tbody>
          {
            hotels && hotels.map(hotel => (
                <tr key={hotel.id}>
                  <td>{hotel.hotelTitle}</td>
                  <td>{new Date(hotel.createdAt).toLocaleDateString()} - {new Date(hotel.createdAt).toLocaleTimeString()}</td>
                  <td>
                    <a style={{color: "red"}} onClick={() => onDeleteBtnClicked(hotel.id)}>
                      <FontAwesomeIcon icon={faTrash}
                                       style={{marginRight: "10px"}}/>
                      Xoá
                    </a>
                  </td>
                  <td>
                    <a style={{color: "green"}} onClick={() => onEditBtnClicked(hotel.id)}>
                      <FontAwesomeIcon icon={faPenToSquare}
                                       style={{marginRight: "10px"}}/>
                      Sửa
                    </a>
                  </td>
                </tr>
            ))
          }
          </tbody>
        </table>
      </>
  )
}

export default HotelsTable
