import axiosClient from "./axiosClient";

const getToken = () => {
  return {
    "Authorization": localStorage.getItem("token")
  }
}

const getAllContact = (page, size, status) => {
  return axiosClient.get(
      `/admin/api/v1/contacts?page=${page}&size=${size}&status=${status}`,
      {
        headers: getToken()
      }
  )
}

const deleteContact = (id) => {
  return axiosClient.delete(
      `/admin/api/v1/contacts/${id}`,
      {
        headers: getToken()
      }
  )
}

const contactService = {
  getAllContact,
  deleteContact
}

export default contactService
