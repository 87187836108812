import axiosClient from "./axiosClient";

const login = (username, password) => {
  return axiosClient.post('/api/v1/login', {
    username,
    password
  })
}

const authService = {
  login
}

export default authService
