import axiosClient from "./axiosClient";

const BASE_PATH = '/admin/api/v1/posts'

const getToken = () => {
  return {
    "Authorization": localStorage.getItem("token")
  }
}

const getAllPostsPaging = (page, size) => {
  return axiosClient.get(
      `${BASE_PATH}?page=${page}&size=${size}`,
      {
        headers: getToken()
      }
  )
}

const getPostDetailById = (postId) => {
  return axiosClient.get(
      `${BASE_PATH}/${postId}`,
      {
        headers: getToken()
      }
  )
}

const updatePostById = (postId, data) => {
  return axiosClient.put(
      `${BASE_PATH}/${postId}`,
      data,
      {
        headers: getToken()
      }
  )
}

const createNewPost = (data) => {
  return axiosClient.post(
      `${BASE_PATH}`,
      data,
      {
        headers: getToken()
      }
  )
}

const deletePostById = (postId) => {
  return axiosClient.delete(
      `${BASE_PATH}/${postId}`,
      {
        headers: getToken()
      }
  )
}


const postService = {
  getAllPostsPaging,
  getPostDetailById,
  deletePostById,
  createNewPost,
  updatePostById
}

export default postService
