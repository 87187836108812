import './hotelAddPage.css'
import {useEffect, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import fileService from "../../../common/api/fileService";
import {Editor} from "@tinymce/tinymce-react";
import {MDBBtn} from "mdb-react-ui-kit";
import hotelService from "../../../common/api/hotelService";
import {useNavigate, useParams} from "react-router-dom";

const HotelAddPage = () => {
  // const staticPrefix = 'http://localhost:8081';
  const staticPrefix = 'https://static.halongtrip.net';
  const [largeImage, setLargeImage] = useState('');
  const [avatar, setAvatar] = useState('');
  const [smallImages, setSmallImages] = useState([]);
  const [hotelTitle, setHotelTitle] = useState('');
  const [hotelAddress, setHotelAddress] = useState('');
  const [hotelDescription, setHotelDescription] = useState('');
  const editorRef = useRef(null);
  const nav = useNavigate();
  const {hotelId} = useParams();

  useEffect(() => {
    if(hotelId){
      //Loading data for edit
      hotelService.getHotelById(hotelId)
      .then(res => {
        const {data} = res;
        const {
          avatar,
          largeImage,
          smallImages,
          hotelTitle,
          hotelAddress,
          hotelDescription
        } = data;
        setAvatar(avatar);
        setLargeImage(largeImage);
        setSmallImages(smallImages);
        setHotelTitle(hotelTitle);
        setHotelAddress(hotelAddress);
        setHotelDescription(hotelDescription);
      })
      .catch(err => {

      })
    }
  }, [hotelId]);



  const handleAvatarImageUpload = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    fileService.uploadFile(formData)
    .then(res => {
      const fileNameUploaded = res.data;
      setAvatar(fileNameUploaded);
    })
    .catch(err => {
      console.error(err)
    })
  }

  const handleLargeImageUpload = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    fileService.uploadFile(formData)
    .then(res => {
      const fileNameUploaded = res.data;
      setLargeImage(fileNameUploaded);
    })
    .catch(err => {
      console.error(err)
    })
  };

  const handleSmallImageUpload = (event) => {
    if (smallImages.length === 4) {
      alert('Bạn đã tải lên đủ 4 ảnh nhỏ');
      return;
    }
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    fileService.uploadFile(formData)
    .then(res => {
      const fileNameUploaded = res.data;
      const newSmallImages = [...smallImages, fileNameUploaded]
      setSmallImages(newSmallImages);
    })
    .catch(err => {
      console.error(err)
    })
  }

  const handleDeleteSmallImages = (smallImage) => {
    const newSmallImages = smallImages.filter(img => img !== smallImage);
    setSmallImages(newSmallImages);
  }

  const handleAddHotelClicked = () => {
    if(hotelTitle === ''){
      alert('Tên khách sạn không được để trống!!!!');
      return;
    }

    if(hotelAddress === ''){
      alert('Địa chỉ khách sạn không được để trống!!!!');
      return;
    }
    const req = {
      avatar,
      largeImage,
      smallImages,
      hotelTitle,
      hotelAddress,
      hotelDescription: editorRef.current.getContent()
    }
    if(hotelId){
      hotelService.updateHotel(hotelId, req)
      .then(res => {
        alert('Cập nhật thành công!!!');
        nav("/hotels")
      })
      .catch(err => {

      })
      return;
    }
    hotelService.createNewHotel(req)
    .then(res => {
      alert('Thêm khách sạn thành công');
      nav("/hotels")
    })
    .catch(err => {

    })
  }

  return (
      <>
        <h2>Tạo khách sạn mới</h2>
        <div>
          <div>
            <input
                style={{width: "60%", marginLeft: "10px"}}
                value={hotelTitle}
                onChange={(e) => setHotelTitle(e.target.value)}
                placeholder={'Tên khách sạn'}
                type="text"/>
          </div>
          <div style={{marginTop: "20px"}}>
            <input
                style={{width: "60%", marginLeft: "10px"}}
                value={hotelAddress}
                onChange={(e) => setHotelAddress(e.target.value)}
                placeholder={'Địa chỉ'}
                type="text"/>
          </div>
          <h5 style={{marginTop: "10px"}}>Tải ảnh đại diện lên</h5>
          <div>
            <input type="file" accept="image/png, image/gif, image/jpeg"
                   onChange={handleAvatarImageUpload}/>
          </div>
          <h5 style={{marginTop: "10px"}}>Thêm ảnh lớn</h5>
          <div>
            <input type="file" accept="image/png, image/gif, image/jpeg"
                   onChange={handleLargeImageUpload}/>
          </div>
          <div className={"img-preview"}>
            <div className={"img-preview-item"}>
              <img src={staticPrefix + avatar} alt=""/>
            </div>
            <div className={"img-preview-item"}>
              <img src={staticPrefix + largeImage} alt=""/>
            </div>
            {
                smallImages && smallImages.map(img => {
                  return (
                      <div className={"img-preview-item"}>
                        <img src={staticPrefix + img} alt=""/>
                        <FontAwesomeIcon
                            onClick={() => handleDeleteSmallImages(img)}
                            className={"trash-icon"}
                            icon={faTrash}/>
                      </div>
                  )
                })
            }
          </div>
          <div className={'upload-small-img'}>
            <h5>Thêm 4 ảnh nhỏ (Tải từng ảnh lên)</h5>
            <div>
              <input type="file" accept="image/png, image/gif, image/jpeg"
                     onChange={handleSmallImageUpload}/>
            </div>
          </div>
          <div style={{marginTop: "40px"}}>
            <h5>Mô tả về khách sạn</h5>
            <Editor
                apiKey='8113ucdfzd1fymzv2968p72vtzh8fw59zxmzdxak49wgpghn'
                onInit={(_evt, editor) => editorRef.current = editor}
                initialValue={hotelDescription}
                init={{
                  height: 400,
                  menubar: false,
                  plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                    'preview',
                    'anchor', 'searchreplace', 'visualblocks', 'code',
                    'fullscreen',
                    'insertdatetime', 'media', 'table', 'code', 'help',
                    'wordcount'
                  ],
                  toolbar: 'undo redo | blocks | ' +
                      'bold italic forecolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | '
                      +
                      'removeformat | help',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
            />
          </div>
          <div style={{marginTop: "20px"}}>
            <MDBBtn onClick={handleAddHotelClicked}>Thêm khách sạn</MDBBtn>
          </div>
        </div>
      </>
  )
}

export default HotelAddPage
