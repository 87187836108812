import './contactsTable.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

const ContactsTable = ({loading, contacts, onDeleteBtnClicked}) => {

  return (
      <>
        <h3 className={"page-title"}>DANH SÁCH LIÊN HỆ</h3>
        {
          loading && (
              <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <div className="spinner-border" role="status">
                </div>
              </div>
            )
        }
        <table>
          <thead>
            <tr>
              <td>Số điện thoại</td>
              <td>Trạng thái</td>
              <td style={{width: "30%"}}>Thông điệp</td>
              <td>Ngày tạo</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
          {
            contacts.map(contact => (
                <tr key={contact.id}>
                  <td>{contact.phone}</td>
                  <td>{contact.status}</td>
                  <td>{contact.msg}</td>
                  <td>{new Date(contact.createdAt).toLocaleDateString()} - {new Date(contact.createdAt).toLocaleTimeString()}</td>
                  <td>
                    <a style={{color: "red"}} onClick={() => onDeleteBtnClicked(contact.id)}>
                      <FontAwesomeIcon icon={faTrash}
                                       style={{marginRight: "10px"}}/>
                      Xoá
                    </a>
                  </td>
                </tr>
            ))
          }
          </tbody>
        </table>
      </>
  )
}

export default ContactsTable
