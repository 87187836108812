import axiosClient from "./axiosClient";

const BASE_PATH = '/admin/api/v1/access-logs'

const getToken = () => {
  return {
    "Authorization": localStorage.getItem("token")
  }
}

const getAllLogsPaging = (page, size) => {
  return axiosClient.get(
      `${BASE_PATH}?page=${page}&size=${size}`,
      {
        headers: getToken()
      }
  )
}

const deleteIp = (ip) => {
  return axiosClient.delete(
      `${BASE_PATH}/${ip}`,
      {
        headers: getToken()
      }
  )
}

const  accessLogService = {
  getAllLogsPaging,
  deleteIp
}


export default accessLogService
