import {Outlet} from "react-router-dom";

const PostPage = () => {
  return (
      <Outlet />
  )
}


export default PostPage
