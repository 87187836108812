import axiosClient from "./axiosClient";

const BASE_PATH = "/admin/api/v1/tours"

const getToken = () => {
  return {
    "Authorization": localStorage.getItem("token")
  }
}

const createNewTour = (req) => {
  return axiosClient.post(
      BASE_PATH,
      req,
      {
        headers: getToken()
      }
  )
}

const getAllTours = (page, size) => {
  return axiosClient.get(
      `${BASE_PATH}?page=${page}&size=${size}`,
      {
        headers: getToken()
      }
  )
}

const getTourById = (tourId) => {
  return axiosClient.get(
      `${BASE_PATH}/${tourId}`,
      {
        headers: getToken()
      }
  )
}

const updateTourById = (tourId, data) => {
  return axiosClient.put(
      `${BASE_PATH}/${tourId}`,
      data,
      {
        headers: getToken()
      }
  )
}

const deleteTourById = (tourId) => {
  return axiosClient.delete(
      `${BASE_PATH}/${tourId}`,
      {
        headers: getToken()
      }
  )
}

const tourService = {
  createNewTour,
  getAllTours,
  getTourById,
  updateTourById,
  deleteTourById
}

export default tourService
