import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

const useAuth = () => {
  const nav = useNavigate()
  const [token, setToken] = useState('');
  useEffect(() => {
    const tokenFromStorage = localStorage.getItem("token")
    if(!tokenFromStorage){
      nav('/login')
    }
    setToken(token)
  }, []);

  return token
}

export default useAuth
