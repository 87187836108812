import './accessLogTable.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

const AccessLogTable = ({loading, logs, onDeleteBtnClicked}) => {
  return (
      <>
        <h3 className={"page-title"}>DANH SÁCH LỊCH SỬ TRUY CẬP</h3>
        {
            loading && (
                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <div className="spinner-border" role="status">
                  </div>
                </div>
            )
        }
        <table>
          <thead>
          <tr>
            <td>IP</td>
            <td>Số lần truy cập</td>
            <td></td>
          </tr>
          </thead>
          <tbody>
          {
            logs && logs.map(log => (
                <tr key={log.ip}>
                  <td>{log.ip}</td>
                  <td>{log.count}</td>
                  <td>
                    <a style={{color: "red"}} onClick={() => onDeleteBtnClicked(log.ip)}>
                      <FontAwesomeIcon icon={faTrash}
                                       style={{marginRight: "10px"}}/>
                      Xoá
                    </a>
                  </td>
                </tr>
            ))
          }
          </tbody>
        </table>
      </>
  )
}

export default AccessLogTable
