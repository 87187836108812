import './login.css'
import {MDBContainer} from "mdb-react-ui-kit";
import {useEffect, useState} from "react";
import authService from "../../common/api/authService";
import {useNavigate} from "react-router-dom";

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const nav = useNavigate();

  useEffect(() => {
    const tokenFromStorage = localStorage.getItem("token");
    if(tokenFromStorage){
      nav("/tours")
    }
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const loginResponse = await authService.login(username, password);
      setLoading(false);
      const loginData = loginResponse.data;
      localStorage.setItem("token", loginData.token)
      console.log('Login data: ', loginData)
      nav('/tours')
    } catch (err) {
      setLoading(false)
      console.error('Login error with message: ', err)
    }
  }

  return (
      <>
        <MDBContainer className={"login-form-container"}>
          <form onSubmit={onSubmit} className={"login-form"}>
            <div className="mb-4">
              <input type="text" className="form-control" placeholder={'Tên đăng nhập'}
                     onChange={(e) => setUsername(e.target.value)}/>
            </div>
            <div className="mb-4">
              <input type="password" className="form-control" placeholder={'Mật khẩu'}
                     onChange={(e) => setPassword(e.target.value)}/>
            </div>
            <button type="submit" className="btn btn-primary btn-block">Đăng
              nhập
            </button>
          </form>
        </MDBContainer>
        <MDBContainer className={"loading-container"}>
          {
              loading && (
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
              )
          }
        </MDBContainer>
      </>
  )
}

export default LoginPage
