import {
  createBrowserRouter,
  RouterProvider,
  useNavigate
} from "react-router-dom";
import LoginPage from "../pages/Login/loginPage";
import RootLayout from "../layout/rootLayout";
import TourPage from "../pages/Tour/tourPage";
import TourListPage from "../pages/Tour/TourListPage/tourListPage";
import TourAddPage from "../pages/Tour/TourAddPage/tourAddPage";
import ContactPage from "../pages/Contact/contactPage";
import ContactListPage from "../pages/Contact/ContactListPage/contactListPage";
import {useEffect} from "react";
import HotelPage from "../pages/Hotel/hotelPage";
import HotelListPage from "../pages/Hotel/HotelListPage/hotelListPage";
import HotelAddPage from "../pages/Hotel/HotelAddPage/hotelAddPage";
import PostPage from "../pages/Post/postPage";
import PostListPage from "../pages/Post/PostListPage/postListPage";
import PostAddPage from "../pages/Post/PostAddPage/postAddPage";
import AccessLogPage from "../pages/AccessLog/accessLogPage";
import AccessLogListPage
  from "../pages/AccessLog/AccessLogListPage/accessLogListPage";

const IndexPage = () => {
  const nav = useNavigate();
  useEffect(() => {
    nav("/tours")
  }, []);
  return <></>
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout/>,
    children: [
      {
        index: true,
        element: <IndexPage/>
      },
      {
        path: "tours",
        element: <TourPage/>,
        children: [
          {
            index: true,
            element: <TourListPage/>
          },
          {
            path: "add",
            element: <TourAddPage/>
          },
          {
            path: "edit/:tourId",
            element: <TourAddPage/>
          }
        ]
      },
      {
        path: "contacts",
        element: <ContactPage/>,
        children: [
          {
            index: true,
            element: <ContactListPage/>
          }
        ]
      }, {
        path: "access-logs",
        element: <AccessLogPage/>,
        children: [
          {
            index: true,
            element: <AccessLogListPage/>
          }
        ]
      },
      {
        path: "hotels",
        element: <HotelPage/>,
        children: [
          {
            index: true,
            element: <HotelListPage/>
          },
          {
            path: "add",
            element: <HotelAddPage/>
          },
          {
            path: "edit/:hotelId",
            element: <HotelAddPage/>
          }
        ]
      },
      {
        path: "posts",
        element: <PostPage/>,
        children: [
          {
            index: true,
            element: <PostListPage/>
          },
          {
            path: "add",
            element: <PostAddPage/>
          },
          {
            path: "edit/:postId",
            element: <PostAddPage/>
          }
        ]
      }
    ]
  },
  {
    path: "/login",
    element: <LoginPage/>
  }
])

const AppRouter = () => {
  return (
      <RouterProvider router={router}/>
  )
}

export default AppRouter
