import {MDBBtn} from "mdb-react-ui-kit";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import tourService from "../../../common/api/tourService";
import {paging} from "../../../common/util/pagingUtil";
import ToursTable from "../conponents/ToursTable/toursTable";
import Paginator from "../../../common/components/Paginator/paginator";

const TourListPage = () => {
  const nav = useNavigate();
  const [pages, setPages] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(0);
  const [tours, setTours] = useState([]);
  const [loading, setLoading] = useState(false)
  const [reload, setReload] = useState(false);

  const handlePageButtonClicked = (page) => {
    setPage(page - 1)
  }

  useEffect(() => {
    tourService.getAllTours(page, 10)
    .then(res => {
      const {totalPages, content} = res.data;
      setTotalPage(totalPages);
      setTours(content)
      setLoading(false)
    })
    .catch(err => {
      setLoading(false)
      console.error('Exception when get all tours: ', err)
    })
  }, [page, reload]);

  useEffect(() => {
    const newPages = paging(page, 5, totalPage)
    setPages(newPages)
  }, [page, totalPage, reload]);

  const handleDeleteTourClicked = (id) => {
    if (window.confirm('Bạn có chắc chắn muốn xoá tour này?')) {
      tourService.deleteTourById(
          id
      ).then(res => {
        alert('Xoá thành công');
        setReload(!reload);
      })
      .catch(err => {
        alert('Xoá tour thất bại, vui lòng thử lại sau');
      })
    }
  }

  const handleEditIconClicked = (id) => {
    nav(`/tours/edit/${id}`)
  }

  return (
      <div style={{marginTop: '20px'}}>
        <MDBBtn onClick={() => nav('/tours/add')}>Thêm tour mới</MDBBtn>
        <ToursTable
            loading={loading}
            tours={tours}
            onDeleteBtnClicked={handleDeleteTourClicked}
            onEditBtnClicked={handleEditIconClicked}
        />
        <Paginator
            page={page}
            totalPage={totalPage}
            pages={pages}
            onPageItemClicked={handlePageButtonClicked}
        />
      </div>
  )
}

export default TourListPage
