import fileService from "../../../common/api/fileService";
import './tourAddPage.css'
import {useEffect, useRef, useState} from "react";
import {MDBBtn} from "mdb-react-ui-kit";
import {Editor} from "@tinymce/tinymce-react";
import tourService from "../../../common/api/tourService";
import {useNavigate, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faPenToSquare
} from '@fortawesome/free-solid-svg-icons'

const TourAddPage = () => {
  // const staticPrefix = 'http://localhost:8081';
  const staticPrefix = 'https://static.halongtrip.net';
  const [largeImage, setLargeImage] = useState('');
  const [avatar, setAvatar] = useState('');
  const [smallImages, setSmallImages] = useState([]);

  const [price, setPrice] = useState('');
  const [fullPackagePrice, setFullPackagePrice] = useState('');
  const [tourSchedule, setTourSchedule] = useState([]);
  const [scheduleTime, setScheduleTime] = useState('');
  const [scheduleDescription, setScheduleDescription] = useState('');
  const [serviceKey, setServiceKey] = useState('1');
  const [service, setService] = useState('');
  const [tourServices, setTourServices] = useState([]);
  const [dayTour, setDayTour] = useState('1');
  const [tourTitle, setTourTitle] = useState('');
  const [editScheduleIndex, setEditScheduleIndex] = useState(-1);
  const [tourNote, setTourNote] = useState('');
  const [tourCancelPolicy, setTourCancelPolicy] = useState('');

  const nav = useNavigate();
  const {tourId} = useParams();

  const editorRef = useRef(null);
  const cancelPolicyRef = useRef(null);

  useEffect(() => {
    if (tourId) {
      //Loading data for edit
      console.log('Loading data for edit id: ', tourId)
      tourService.getTourById(tourId)
      .then(res => {
        const {data} = res;
        const {
          avatar,
          tourTitle,
          largeImage,
          smallImages,
          price,
          fullPackagePrice,
          tourServices,
          note,
          cancelPolicy,
          tourSchedules,
          dayTour
        } = data;
        setAvatar(avatar)
        setTourTitle(tourTitle)
        setLargeImage(largeImage);
        setSmallImages(smallImages);
        setPrice(price);
        setFullPackagePrice(fullPackagePrice);
        setTourServices(tourServices);
        setTourNote(note);
        setTourCancelPolicy(cancelPolicy);
        setTourSchedule(tourSchedules);
        setDayTour(dayTour);
      })
      .catch(err => {
        alert('Đã xảy ra lỗi vui lòng thử lại sau');
      })
    }
  }, [tourId]);

  const handleAvatarImageUpload = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    fileService.uploadFile(formData)
    .then(res => {
      const fileNameUploaded = res.data;
      setAvatar(fileNameUploaded);
    })
    .catch(err => {
      console.error(err)
    })
  }

  const handleLargeImageUpload = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    fileService.uploadFile(formData)
    .then(res => {
      const fileNameUploaded = res.data;
      setLargeImage(fileNameUploaded);
    })
    .catch(err => {
      console.error(err)
    })
  };

  const handleSmallImageUpload = (event) => {
    if (smallImages.length === 4) {
      alert('Bạn đã tải lên đủ 4 ảnh nhỏ');
      return;
    }
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    fileService.uploadFile(formData)
    .then(res => {
      const fileNameUploaded = res.data;
      const newSmallImages = [...smallImages, fileNameUploaded]
      setSmallImages(newSmallImages);
    })
    .catch(err => {
      console.error(err)
    })
  }

  const handleDeleteSmallImages = (smallImage) => {
    const newSmallImages = smallImages.filter(img => img !== smallImage);
    setSmallImages(newSmallImages);
  }

  const handleAddSchedule = () => {
    if (editScheduleIndex === -1) {
      //Add new
      const newTourSchedule = [...tourSchedule, {
        time: scheduleTime,
        scheduleDescription
      }];
      setTourSchedule(newTourSchedule)
      setScheduleTime('');
      setScheduleDescription('');
      return;
    }
    //Edit
    const newTourSchedule = [...tourSchedule]
    newTourSchedule[editScheduleIndex] = {
      time: scheduleTime,
      scheduleDescription
    }
    setTourSchedule(newTourSchedule)
    setScheduleTime('');
    setScheduleDescription('');
    setEditScheduleIndex(-1);
  }

  const handleDeleteSchedule = (scheduleDes) => {
    const newTourSchedule = tourSchedule.filter(
        ts => ts.scheduleDescription !== scheduleDes);
    setTourSchedule(newTourSchedule);
  }

  const handleEditScheduleClicked = (index) => {
    setEditScheduleIndex(index);
    const sc = tourSchedule[index];
    setScheduleTime(sc.time);
    setScheduleDescription(sc.scheduleDescription)
  }

  const handleAddService = () => {
    const newServices = [
      ...tourServices,
      {
        serviceKey,
        service
      }
    ]
    setTourServices(newServices);
    setService('');
  }

  const handleDeleteServiceClicked = (service) => {
    const newTourService = tourServices.filter(s => s.service !== service);
    setTourServices(newTourService);
  }

  const handleAddTourClicked = () => {
    const req = {
      avatar,
      tourTitle,
      dayTour: dayTour === '1',
      largeImage,
      smallImages,
      price,
      fullPackagePrice,
      tourSchedules: tourSchedule,
      tourServices,
      note: editorRef.current.getContent(),
      cancelPolicy: cancelPolicyRef.current.getContent()
    }
    if(tourId){
      //Update here
      tourService.updateTourById(tourId, req)
      .then(res => {
        alert('Cập nhật tour thành công!!!');
        nav("/tours");
      })
      .catch(err => {
        alert('Update error')
      })
      return;
    }
    tourService.createNewTour(req)
    .then(res => {
      alert("Create new tour done!!!!");
      nav("/tours")
    })
    .catch(err => {
      console.error("Exception when create new tour: ", err)
    })
  }

  return (
      <>
        <h2>
          {
            tourId && 'Sửa tour'
          }
          {
            !tourId && 'Tạo tour mới'
          }
        </h2>
        <div>
          <div className={"tour-service-input"}>
            <div style={{display: "flex"}}>
              <select
                  onChange={(e) => {
                    setDayTour(e.target.value);
                  }}
                  value={dayTour}>
                <option value={'1'} selected={dayTour === '1'}>
                  Tour ngày
                </option>
                <option value={'0'} selected={dayTour === '0'}>
                  Tour đêm
                </option>
              </select>
              <input
                  style={{width: "100%", marginLeft: "10px"}}
                  value={tourTitle}
                  onChange={(e) => setTourTitle(e.target.value)}
                  placeholder={'Tên tour'}
                  type="text"/>
            </div>
          </div>
          <h5 style={{marginTop: "10px"}}>Tải ảnh đại diện lên</h5>
          <div>
            <input type="file" accept="image/png, image/gif, image/jpeg"
                   onChange={handleAvatarImageUpload}/>
          </div>
          <h5 style={{marginTop: "10px"}}>Thêm ảnh lớn</h5>
          <div>
            <input type="file" accept="image/png, image/gif, image/jpeg"
                   onChange={handleLargeImageUpload}/>
          </div>
          <div className={"img-preview"}>
            <div className={"img-preview-item"}>
              <img src={staticPrefix + avatar} alt=""/>
            </div>
            <div className={"img-preview-item"}>
              <img src={staticPrefix + largeImage} alt=""/>
            </div>
            {
                smallImages && smallImages.map(img => {
                  return (
                      <div className={"img-preview-item"}>
                        <img src={staticPrefix + img} alt=""/>
                        <FontAwesomeIcon
                            onClick={() => handleDeleteSmallImages(img)}
                            className={"trash-icon"}
                            icon={faTrash}/>
                      </div>
                  )
                })
            }
          </div>
          <div className={'upload-small-img'}>
            <h5>Thêm 4 ảnh nhỏ (Tải từng ảnh lên)</h5>
            <div>
              <input type="file" accept="image/png, image/gif, image/jpeg"
                     onChange={handleSmallImageUpload}/>
            </div>
          </div>
          <div className={"tour-price"}>
            <div className={"tour-price-item"}>
              <input
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  type="text"
                  placeholder={'Giá tour'}/>
            </div>
            <div className={"tour-price-item"}>
              <input
                  value={fullPackagePrice}
                  onChange={(e) => setFullPackagePrice(e.target.value)}
                  type="text"
                  placeholder={'Giá tour trọn gói'}/>
            </div>
          </div>
          <div className={"schedule-input"}>
            <h5>Thêm lịch trình tour</h5>
            <div style={{display: "flex"}}>
              <div className={'schedule-item'} style={{width: "15%"}}>
                <input
                    value={scheduleTime}
                    onChange={(e) => setScheduleTime(e.target.value)}
                    placeholder={'Nhập giờ'}
                    type="text"/>
              </div>
              <div className={'schedule-item'} style={{width: "100%"}}>
                <input
                    value={scheduleDescription}
                    onChange={(e) => setScheduleDescription(e.target.value)}
                    placeholder={'Nhập mô tả lịch trình'}
                    type="text"/>
              </div>
            </div>
            <div style={{marginTop: "10px"}}>
              <MDBBtn onClick={handleAddSchedule}>
                {
                    (editScheduleIndex !== -1) && 'Sửa lịch trình'
                }
                {
                    !(editScheduleIndex !== -1) && 'Thêm lịch trình'
                }
              </MDBBtn>
            </div>
            <div style={{marginTop: "10px"}}>
              <ul>
                {
                  tourSchedule.map((s, index) => {
                    return (
                        <li key={s.time}>
                          <FontAwesomeIcon
                              onClick={() => handleDeleteSchedule(
                                  s.scheduleDescription)}
                              className={"trash-icon"}
                              icon={faTrash}/>
                          <FontAwesomeIcon
                              style={{marginLeft: "10px"}}
                              onClick={() => handleEditScheduleClicked(index)}
                              className={"edit-icon"}
                              icon={faPenToSquare}/>
                          <span style={{marginLeft: "20px"}}>{s.time}</span>
                          <span
                              style={{marginLeft: "20px"}}>{s.scheduleDescription}</span>
                        </li>
                    )
                  })
                }
              </ul>
            </div>
          </div>
          <div className={"tour-service-input"}>
            <h5>Dịch vụ trên du thuyền</h5>
            <div style={{display: "flex"}}>
              <select
                  onChange={(e) => {
                    setServiceKey(e.target.value);
                  }}
                  value={serviceKey}>
                <option value={'1'}>
                  Bao gồm
                </option>
                <option value={'0'}>
                  Không bao gồm
                </option>
              </select>
              <input
                  style={{width: "100%", marginLeft: "10px"}}
                  value={service}
                  onChange={(e) => setService(e.target.value)}
                  placeholder={'Dịch vụ'}
                  type="text"/>
            </div>
            <div style={{marginTop: "10px"}}>
              <MDBBtn onClick={handleAddService}>Thêm dịch vụ</MDBBtn>
            </div>
            <ul style={{marginTop: "20px"}}>
              {
                tourServices.map(sv => {
                  return (
                      <li key={sv.service}>
                        <FontAwesomeIcon
                            onClick={() => handleDeleteServiceClicked(
                                sv.service)}
                            style={{marginRight: "10px"}}
                            className={"trash-icon"}
                            icon={faTrash}/>
                        {
                          sv.serviceKey === '1' ? <span
                                  style={{fontWeight: "bold"}}>Bao gồm</span> :
                              <span
                                  style={{fontWeight: "bold"}}>Không bao gồm</span>
                        }
                        {
                          <span style={{marginLeft: "10px"}}>{sv.service}</span>
                        }
                      </li>
                  )
                })
              }
            </ul>
          </div>
          <div style={{marginTop: "40px"}}>
            <h5>Chính sách hoàn huỷ</h5>
            <Editor
                apiKey='8113ucdfzd1fymzv2968p72vtzh8fw59zxmzdxak49wgpghn'
                onInit={(_evt, editor) => cancelPolicyRef.current = editor}
                initialValue={tourCancelPolicy}
                init={{
                  height: 200,
                  menubar: false,
                  plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                    'preview',
                    'anchor', 'searchreplace', 'visualblocks', 'code',
                    'fullscreen',
                    'insertdatetime', 'media', 'table', 'code', 'help',
                    'wordcount'
                  ],
                  toolbar: 'undo redo | blocks | ' +
                      'bold italic forecolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | '
                      +
                      'removeformat | help',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
            />
          </div>
          <div style={{marginTop: "40px"}}>
            <h5>Lưu ý tour</h5>
            <Editor
                apiKey='8113ucdfzd1fymzv2968p72vtzh8fw59zxmzdxak49wgpghn'
                onInit={(_evt, editor) => editorRef.current = editor}
                initialValue={tourNote}
                init={{
                  height: 200,
                  menubar: false,
                  plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                    'preview',
                    'anchor', 'searchreplace', 'visualblocks', 'code',
                    'fullscreen',
                    'insertdatetime', 'media', 'table', 'code', 'help',
                    'wordcount'
                  ],
                  toolbar: 'undo redo | blocks | ' +
                      'bold italic forecolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | '
                      +
                      'removeformat | help',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
            />
          </div>
          <div style={{marginTop: "20px"}}>
            <MDBBtn onClick={handleAddTourClicked}>
              {
                  tourId && 'Sửa tour'
              }
              {
                  !tourId && 'Tạo tour mới'
              }
            </MDBBtn>
          </div>
        </div>
      </>
  )
}

export default TourAddPage
