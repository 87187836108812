import axiosClient from "./axiosClient";

const getToken = () => {
  return {
    "Authorization": localStorage.getItem("token")
  }
}

const uploadFile = (data) => {
  return axiosClient.post('/admin/api/v1/files',
      data,
      {
        headers: getToken()
      }
  )
}

const fileService = {
  uploadFile
}

export default fileService
