import axios from "axios";

// const BASE_HOST = "http://localhost:8080"
const BASE_HOST = "https://halongtrip.net"

const axiosClient = axios.create({
  baseURL: BASE_HOST
})


axiosClient.interceptors.response.use(
    function(response) {
      // Do something with successful response
      return response;
    },
    function(error) {
      // Handle 403 error globally
      if (error.response && error.response.status === 403) {
        // Handle 403 error here, for example, redirect to a login page or display an error message
        localStorage.removeItem("token");
        window.location = '/login';
      }
      return Promise.reject(error);
    }
);


export default axiosClient
