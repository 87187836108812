import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";

const MenuItem = ({icon, title, path}) => {
  const nav = useNavigate();
  const [active, setActive] = useState(false);
  const {pathname} = useLocation();

  useEffect(() => {
    const mainPath = path.split("/")[1];
    const mainPathFromLocation = pathname.split("/")[1];
    setActive(mainPath === mainPathFromLocation)
  }, [pathname]);

  const handleItemClicked = () => {
    nav(path);
  }

  return (
      <div className={"menu-item " + (active ? 'menu-active' : '')} onClick={handleItemClicked}>
        <FontAwesomeIcon icon={icon} size={"lg"}
                         style={{marginRight: "10px"}}/>
        {
          title
        }
      </div>
  )
}

export default MenuItem
