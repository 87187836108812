import useAuth from "../common/hook/useAuth";
import './rootLayout.css'
import {Outlet, useNavigate} from "react-router-dom";
import logo from '../asset/img/logo.svg'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faRightFromBracket,
  faShip,
  faBook,
  faBlog,
  faHotel,
  faEarthAsia
} from '@fortawesome/free-solid-svg-icons'
import MenuItem from "../common/components/MenuItem/menuItem";
import {useState} from "react";

const RootLayout = () => {
  const menuItems = [
    {
      title: 'Quản lý tour',
      icon: faShip,
      index: 0,
      path: "/tours"
    },
    {
      title: 'Quản lý khách sạn',
      icon: faHotel,
      index: 1,
      path: "/hotels"
    },
    {
      title: 'Quản lý bài viết',
      icon: faBlog,
      index: 2,
      path: "/posts"
    },
    {
      title: 'Quản lý liên hệ',
      icon: faBook,
      index: 3,
      path: "/contacts"
    },
    {
      title: 'Danh sách truy cập',
      icon: faEarthAsia,
      index: 4,
      path: "/access-logs"
    }
  ];

  const [currentActive, setCurrentActive] = useState(0);

  const nav = useNavigate();
  const handleLogoutClicked = () => {
    localStorage.removeItem("token");
    nav("/login")
  }

  // useEffect(() => {
  //   nav("/tours")
  // }, []);

  useAuth();
  return (
      <div className={"page-container"}>
        <div className={"side-nav"}>
          <div className={"logo"}>
            <img src={logo} alt=""/>
          </div>
          <div className={"menu"}>
            {
              menuItems.map(item => {
                return (
                    <MenuItem
                        key={item.index}
                        title={item.title}
                        icon={item.icon}
                        path={item.path}
                    />
                )
              })
            }
          </div>
          <div className={"logout"} onClick={handleLogoutClicked}>
            <FontAwesomeIcon icon={faRightFromBracket} size={"lg"}
                             style={{marginRight: "10px"}}/>
            Đăng xuất
          </div>
        </div>
        <div className={"page-body"}>
          <Outlet/>
        </div>
      </div>
  )
}

export default RootLayout
