import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import contactService from "../../../common/api/contactService";
import {paging} from "../../../common/util/pagingUtil";
import accessLogService from "../../../common/api/accessLogService";
import AccessLogPage from "../accessLogPage";
import AccessLogTable from "../components/AccessLogTable/accessLogTable";
import Paginator from "../../../common/components/Paginator/paginator";

const AccessLogListPage = () => {
  const [pages, setPages] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(0);
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false)
  const [reload, setReload] = useState(false);
  const nav = useNavigate();

  const handlePageButtonClicked = (page) => {
    setPage(page - 1)
  }

  useEffect(() => {
    setLoading(true)
    accessLogService.getAllLogsPaging(page, 10)
    .then(data => {
      const {totalPages, content} = data.data;
      setTotalPage(totalPages);
      setLogs(content)
      setLoading(false)
    })
    .catch(err => {
      setLoading(false)
    })
  }, [page, reload]);

  useEffect(() => {
    const newPages = paging(page, 5, totalPage)
    setPages(newPages)
  }, [page, totalPage, reload]);

  const handleDeleteContactClicked = (ip) => {
    if(window.confirm('Bạn có chắc chắn muốn xoá ip này?')){
      accessLogService.deleteIp(ip)
      .then(res => {
        alert('Xoá thành công');
        setReload(!reload)
      })
      .catch(err => {
        alert('Đã xảy ra lỗi, vui lòng thử lại sau');
      })
    }
  }

  return (
      <>
        <AccessLogTable
            loading={loading}
            logs={logs}
            onDeleteBtnClicked={handleDeleteContactClicked}
        />
        <Paginator
            page={page}
            totalPage={totalPage}
            pages={pages}
            onPageItemClicked={handlePageButtonClicked}
        />
      </>
  )
}

export default AccessLogListPage
