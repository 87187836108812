import './postListPage.css'
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import postService from "../../../common/api/postSerivce";
import {paging} from "../../../common/util/pagingUtil";
import {MDBBtn} from "mdb-react-ui-kit";
import Paginator from "../../../common/components/Paginator/paginator";
import PostsTable from "../components/PostsTable/postsTable";

const PostListPage = () => {
  const nav = useNavigate();
  const [pages, setPages] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(0);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false)
  const [reload, setReload] = useState(false);

  const handlePageButtonClicked = (page) => {
    setPage(page - 1)
  }

  useEffect(() => {
    postService.getAllPostsPaging(page, 10)
    .then(res => {
      const {totalPages, content} = res.data;
      console.log(content)
      setTotalPage(totalPages);
      setPosts(content)
      setLoading(false)
    })
    .catch(err => {
      setLoading(false)
      console.error('Exception when get all posts: ', err)
    })
  }, [page, reload]);

  useEffect(() => {
    const newPages = paging(page, 5, totalPage)
    setPages(newPages)
  }, [page, totalPage, reload]);

  const handleDeleteClicked = (id) => {
    if(window.confirm('Bạn có chắc chắn muốn xoá post này?')){
      postService.deletePostById(id)
      .then(res => {
        alert('Xoá thành công!');
        setReload(!reload);
      })
    }
  }

  const handleEditIconClicked = (id) => {
    nav(`/posts/edit/${id}`)
  }

  return (
      <div style={{marginTop: '20px'}}>
        <MDBBtn onClick={() => nav('/posts/add')}>Thêm bài viết mới</MDBBtn>
        <PostsTable
            loading={loading}
            posts={posts}
            onDeleteBtnClicked={handleDeleteClicked}
            onEditBtnClicked={handleEditIconClicked}
        />
        <Paginator
            page={page}
            totalPage={totalPage}
            pages={pages}
            onPageItemClicked={handlePageButtonClicked}
        />
      </div>
  )
}

export default PostListPage
