import './postAddPage.css'
import {useEffect, useRef, useState} from "react";
import fileService from "../../../common/api/fileService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {Editor} from "@tinymce/tinymce-react";
import {MDBBtn} from "mdb-react-ui-kit";
import {useNavigate, useParams} from "react-router-dom";
import postService from "../../../common/api/postSerivce";

const PostAddPage = () => {
  // const staticPrefix = 'http://localhost:8081';
  const staticPrefix = 'https://static.halongtrip.net';
  const [avatar, setAvatar] = useState('');
  const [postTitle, setPostTitle] = useState('');
  const [content, setContent] = useState('');
  const editorRef = useRef(null);
  const nav = useNavigate();
  const {postId} = useParams();

  useEffect(() => {
    if (postId) {
      //Loading data for edit page
      postService.getPostDetailById(postId)
      .then(res => {
        const {data} = res;
        const {
          avatar,
          postTitle,
          content
        } = data;
        setPostTitle(postTitle);
        setAvatar(avatar);
        setContent(content)
      })
      .catch(err => {

      })
    }
  }, [postId]);

  const handleAvatarImageUpload = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    fileService.uploadFile(formData)
    .then(res => {
      const fileNameUploaded = res.data;
      setAvatar(fileNameUploaded);
    })
    .catch(err => {
      console.error(err)
    })
  }

  const handleAddPostClicked = () => {
    const req = {
      postTitle,
      avatar,
      content: editorRef.current.getContent()
    }
    if (postId) {
      postService.updatePostById(postId, req)
      .then(res => {
        alert('Cập nhật thành công!!!');
        nav("/posts")
      })
      .catch(err => {

      })
      return;
    }
    postService.createNewPost(req)
    .then(res => {
      alert('Thêm bài viết thành công!');
      nav("/posts")
    }).catch(err => {

    })
  }

  return (
      <>
        <h2>
          {
              postId && 'Sửa bài viết'
          }
          {
              !postId && 'Tạo bài viết mới'
          }
        </h2>
        <div>
          <div>
            <input
                style={{width: "60%", marginLeft: "10px"}}
                value={postTitle}
                onChange={(e) => setPostTitle(e.target.value)}
                placeholder={'Tên bài viết'}
                type="text"/>
          </div>
          <h5 style={{marginTop: "10px"}}>Tải ảnh đại diện lên</h5>
          <div>
            <input type="file" accept="image/png, image/gif, image/jpeg"
                   onChange={handleAvatarImageUpload}/>
          </div>
          <div className={"img-preview"}>
            <div className={"img-preview-item"}>
              <img src={staticPrefix + avatar} alt=""/>
            </div>
          </div>
          <div style={{marginTop: "40px"}}>
            <h5>Nội dung bài viết</h5>
            <Editor
                imageClassList={
                  [
                    {title: 'None', value: ''},
                    {title: 'No border', value: 'img_no_border'},
                    {
                      title: 'Borders',
                      menu: [
                        {title: 'Green border', value: 'img_green_border'},
                        {title: 'Blue border', value: 'img_blue_border'},
                        {title: 'Red border', value: 'img_red_border'}
                      ]
                    }
                  ]
                }
                apiKey='8113ucdfzd1fymzv2968p72vtzh8fw59zxmzdxak49wgpghn'
                onInit={(_evt, editor) => editorRef.current = editor}
                initialValue={content}
                init={{
                  height: 600,
                  menubar: false,
                  plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                    'preview',
                    'anchor', 'searchreplace', 'visualblocks', 'code',
                    'fullscreen',
                    'insertdatetime', 'media', 'table', 'code', 'help',
                    'wordcount'
                  ],
                  toolbar: 'undo redo | blocks | ' +
                      'bold italic forecolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | '
                      +
                      'removeformat | help',
                  content_style: 'body { font-family:"Josefin Sans",sans-serif; font-size:14px }'
                }}
            />
          </div>
          <div style={{marginTop: "20px"}}>
            <MDBBtn onClick={handleAddPostClicked}>
              {
                  postId && 'Sửa bài viết'
              }
              {
                  !postId && 'Thêm bài viết'
              }
            </MDBBtn>
          </div>
        </div>
      </>
  )
}

export default PostAddPage
