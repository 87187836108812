import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare, faTrash} from "@fortawesome/free-solid-svg-icons";

const PostsTable = ({loading, posts, onDeleteBtnClicked, onEditBtnClicked}) => {
  return (
      <>
        <h3 className={"page-title"}>DANH SÁCH BÀI VIẾT</h3>
        {
            loading && (
                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <div className="spinner-border" role="status">
                  </div>
                </div>
            )
        }
        <table>
          <thead>
          <tr>
            <td>Tên khách sạn</td>
            <td>Ngày tạo</td>
            <td></td>
            <td></td>
          </tr>
          </thead>
          <tbody>
          {
              posts && posts.map(post => (
                  <tr key={post.id}>
                    <td>{post.postTitle}</td>
                    <td>{new Date(post.createdAt).toLocaleDateString()} - {new Date(post.createdAt).toLocaleTimeString()}</td>
                    <td>
                      <a style={{color: "red"}} onClick={() => onDeleteBtnClicked(post.id)}>
                        <FontAwesomeIcon icon={faTrash}
                                         style={{marginRight: "10px"}}/>
                        Xoá
                      </a>
                    </td>
                    <td>
                      <a style={{color: "green"}} onClick={() => onEditBtnClicked(post.id)}>
                        <FontAwesomeIcon icon={faPenToSquare}
                                         style={{marginRight: "10px"}}/>
                        Sửa
                      </a>
                    </td>
                  </tr>
              ))
          }
          </tbody>
        </table>
      </>
  )
}

export default PostsTable
