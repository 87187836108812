import './toursTable.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash, faPenToSquare} from "@fortawesome/free-solid-svg-icons";

const ToursTable = ({loading, tours, onDeleteBtnClicked, onEditBtnClicked}) => {
  return (
      <>
        <h3 className={"page-title"}>DANH SÁCH TOUR</h3>
        {
            loading && (
                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <div className="spinner-border" role="status">
                  </div>
                </div>
            )
        }
        <table>
          <thead>
          <tr>
            <td>Tên tour</td>
            <td>Ngày tạo</td>
            <td></td>
            <td></td>
          </tr>
          </thead>
          <tbody>
          {
            tours && tours.map(tour => (
                <tr key={tour.id}>
                  <td>{tour.tourTitle}</td>
                  <td>{new Date(tour.createdAt).toLocaleDateString()} - {new Date(tour.createdAt).toLocaleTimeString()}</td>
                  <td>
                    <a style={{color: "red"}} onClick={() => onDeleteBtnClicked(tour.id)}>
                      <FontAwesomeIcon icon={faTrash}
                                       style={{marginRight: "10px"}}/>
                      Xoá
                    </a>
                  </td>
                  <td>
                    <a style={{color: "green"}} onClick={() => onEditBtnClicked(tour.id)}>
                      <FontAwesomeIcon icon={faPenToSquare}
                                       style={{marginRight: "10px"}}/>
                      Sửa
                    </a>
                  </td>
                </tr>
            ))
          }
          </tbody>
        </table>
      </>
  )
}

export default ToursTable
