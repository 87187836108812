import axiosClient from "./axiosClient";

const BASE_PATH = '/admin/api/v1/hotels'

const getToken = () => {
  return {
    "Authorization": localStorage.getItem("token")
  }
}

const getAllHotelsPaging = (page, size) => {
  return axiosClient.get(
      `${BASE_PATH}?page=${page}&size=${size}`,
      {
        headers: getToken()
      }
  )
}

const createNewHotel = (data) => {
  return axiosClient.post(
      BASE_PATH,
      data,
      {
        headers: getToken()
      }
  )
}

const getHotelById = (hotelId) => {
  return axiosClient.get(
      `${BASE_PATH}/${hotelId}`,
      {
        headers: getToken()
      }
  )
}

const updateHotel = (hotelId, data) => {
  return axiosClient.put(
      `${BASE_PATH}/${hotelId}`,
      data,
      {
        headers: getToken()
      }
  )
}

const deleteHotelById = (hotelId) => {
  return axiosClient.delete(
      `${BASE_PATH}/${hotelId}`,
      {
        headers: getToken()
      }
  )
}

const hotelService = {
  getAllHotelsPaging,
  createNewHotel,
  getHotelById,
  updateHotel,
  deleteHotelById
}

export default hotelService
